
import {
  defineComponent,
  watch,
  ref,
  reactive,
  PropType,
  onMounted
} from 'vue'
import type { MessageTagsSet } from '@/api/model/messageModel'
// import { findByTeam, findByTeamAndOrg } from '@/api/customer'

export default defineComponent({
  name: 'TagsControl',
  components: { },
  emits: ['add', 'remove'],
  props: [
    'modelValue',
    'tagType',
    'limit'
  ],
  setup(props, { emit }) {
    // 标签相关
    // ---------------------------------------------
    // const tags = props.modelValue
    const formData = ref<MessageTagsSet[]>(
      props.modelValue
    )
    const tagsInputVisible = ref(false)
    const newTagValue = ref<string>()
    // 新增标签输入框
    const showTagsInput = () => {
      tagsInputVisible.value = true
    }
    // 新增标签的事件
    const handleTagsInputConfirm = () => {
      if (newTagValue.value && newTagValue.value.toString().trim() !== '') {
        const _data = {
          dataType: props.tagType,
          dataGroup: props.tagType,
          dataKey: newTagValue.value,
          dataValue: newTagValue.value
        }
        // 异步提交新增标签
        emit('add', _data, (res) => {
          if (res) {
            if (newTagValue.value) {
              formData.value.push(_data)
            }
            tagsInputVisible.value = false
            newTagValue.value = ''
          }
        })
      } else {
        tagsInputVisible.value = false
        newTagValue.value = ''
      }
    }
    // 删除标签
    const handleTagClose = (index, id) => {
      emit('remove', id, (res) => {
        if (res) {
          // 异步删除标签
          if (formData.value) {
            formData.value.splice(index, 1)
          }
        }
      })
    }
    watch(() => [props.modelValue], () => {
      formData.value = props.modelValue
    },
    { deep: true, immediate: true } // 深度监听的参数
    )
    return {
      formData,
      tagsInputVisible,
      newTagValue,
      handleTagClose,
      handleTagsInputConfirm,
      showTagsInput
    }
  }
})
