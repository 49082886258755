<template>
  <app-page>
    <div class="form-container form-message-container">
      <block-title>
        <div class="block-title_txt" style="width:100px;">
          {{$route.query.id && $route.query.id !== '' ? '编辑消息' : '新增消息'}}
        </div>
      </block-title>
      <el-form
        ref="mainForm"
        :disabled="formDisabled"
        :rules="rules"
        v-loading="loading"
        :model="formData"
        label-position="right"
        label-width="100px"
        @submit.prevent="handleSubmit"
      >
        <el-form-item
          prop="title"
          label="消息标题"
          :rules="{ required: true, message: '请填写消息标题' }"
        >
          <el-input v-model="formData.title" placeholder="请填写消息标题" maxlength="20" show-word-limit/>
        </el-form-item>
        <el-form-item
          prop="msgType"
          label="消息类型"
          :rules="{ required: true, message: '请选择消息类型' }"
        >
          <el-select
            v-model="formData.msgType"
            placeholder="请选择消息类型"
            style="width: 100%"
          >
            <el-option :key="item.dataKey" v-for="item in msgTypeEnum" :label="item.dataValue" :value="item.dataKey"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="displayTypeList"
          label="显示形式"
        >
        <el-checkbox-group v-model="formData.displayTypeList">
          <el-checkbox :label="item.dataKey" :key="item.dataKey" v-for="item in msgDisplayEnum">{{item.dataValue}}</el-checkbox>
        </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-if="formData.displayTypeList && formData.displayTypeList.length > 0"
          prop="displayTime"
          :required="true"
          label="展示时间"
        >
          <el-date-picker
              v-model="formData.displayTime"
              type="datetimerange"
              :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 100%"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
        <el-form-item
          prop="forceStartTime"
          label="生效时间"
        >
          <el-date-picker
              v-model="formData.forceStartTime"
              type="datetime"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 100%"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
        <el-form-item
          prop="relatedUrl"
          label="关联页面"
        >
          <el-input v-model="formData.relatedUrl" placeholder="请填写关联页面" />
        </el-form-item>
        <el-form-item
          prop="content"
          label="消息内容"
          :rules="{ required: true, message: '请输入消息内容' }"
        >
          <el-input style="" rows="5" v-model="formData.content" placeholder="请填写消息内容" type="textarea" maxlength="120" show-word-limit/>
        </el-form-item>
        <el-form-item
          prop="msgStatus"
          label="消息状态"
        >
          <el-select
            v-model="formData.msgStatus"
            placeholder="请选择消息类型"
            style="width: 100%"
          >
            <el-option :key="item.dataKey" v-for="item in msgStatusEnum" :label="item.dataValue" :value="item.dataKey"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="tags"
          label="消息标签"
        >
        <div class="tag-container">
          <el-tag
              v-for="tag in formData.tags"
              :key="tag"
              :closable="!formDisabled"
              :disable-transitions="false"
              @close="handleTagClose(tag)"
          >
              {{ tag }}
          </el-tag>
          <el-input
              v-if="tagsInputVisible"
              ref="saveTagInput"
              v-model="newTagValue"
              class="input-new-tag"
              size="small"
              @keyup.enter="handleTagsInputConfirm"
              @blur="handleTagsInputConfirm"
          >
          </el-input>
          <el-button class="button-new-tag" v-if="formData.tags.length <= 6" size="small" @click="showTagsInput">+ 新标签</el-button>
        </div>
        </el-form-item>
        <!-- <el-form-item
          prop="teamName"
          label="通知形式"
          :rules="{ required: true, message: '请输入账户名称' }"
        >
            <el-radio-group v-model="radio">
                <el-radio :label="3">Option A</el-radio>
                <el-radio :label="6">Option B</el-radio>
                <el-radio :label="9">Option C</el-radio>
            </el-radio-group>
        </el-form-item> -->
        <el-form-item
          prop="teamName"
          label="通知范围">
          <plat-and-roles v-model="formData.roleIds" v-if="platEnum" :platEnum="platEnum"></plat-and-roles>
        </el-form-item>
        <el-form-item class="form-footer" v-if="!formDisabled">
          <el-button
            type="primary"
            native-type="submit"
            :loading="submitting"
          >保存</el-button>
          <el-button
            @click="handleBack()"
          >返回</el-button>
        </el-form-item>
      </el-form>
      <div style="text-align: right;" v-if="formDisabled">
        <el-button
          @click="handleBack()"
        >返回</el-button>
      </div>
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { addMessage, updateMessage, getMessageDetail } from '@/api/message'
import type { AddMessageMSet } from '@/api/model/messageModel'
import { messageDisplayTypeEnum } from '@/config/enums/message'
import PlatAndRoles from './components/PlatAndRoles.vue'

function toFormData(searchFormData: AddMessageMSet): AddMessageMSet {
  const roleTemp = {
    // admin: 'adminRoleIds',
    settle: 'settleRoleIds'
    // business: 'businessRoleIds'
  }
  const data: AddMessageMSet = {}
  delete searchFormData.label
  Object.keys(searchFormData).forEach(item => {
    if (item === 'displayTime' && searchFormData.displayTime && searchFormData.displayTime.length > 0) {
      data.displayStartTime = searchFormData.displayTime[0]
      data.displayEndTime = searchFormData.displayTime[1]
    } else if (item === 'tags') {
      if (searchFormData.tags && searchFormData.tags.length > 0) {
        data.label = searchFormData.tags.join(',')
      } else {
        data.label = ''
      }
    } else if (item === 'roleIds') {
      searchFormData.roleIds && Object.keys(searchFormData.roleIds).map(item => {
        if (searchFormData.roleIds) {
          data[roleTemp[item]] = searchFormData.roleIds[item].join(',')
        }
      })
    } else if (item === 'displayTypeList') {
      if (searchFormData.displayTypeList && searchFormData.displayTypeList.length > 0) {
        data.displayType = searchFormData.displayTypeList.join(',')
      } else {
        data.displayType = ''
      }
    } else if (item === 'displayType') {

    } else {
      if (searchFormData[item] !== '') {
        data[item] = searchFormData[item]
      }
    }
  })
  return data
}

function detailToFormData(searchFormData: AddMessageMSet): AddMessageMSet {
  const data: AddMessageMSet = {
    displayTime: [],
    forceTime: [],
    tags: []
  }
  const roleTemp = {
    adminRoleIds: 'admin',
    settleRoleIds: 'settle',
    businessRoleIds: 'business'
  }
  data.displayTime = ['', '']
  data.tags = []
  delete searchFormData.roleIds
  Object.keys(searchFormData).forEach(item => {
    if (item === 'displayStartTime') {
      if (data.displayTime === undefined) {
        data.displayTime = []
      }
      data.displayTime[0] = searchFormData.displayStartTime || ''
    } else if (item === 'displayEndTime') {
      if (data.displayTime === undefined) {
        data.displayTime = []
      }
      data.displayTime[1] = searchFormData.displayEndTime || ''
    } else if (item === 'label') {
      if (searchFormData.label === undefined) {
        searchFormData.label = ''
      }
      if (searchFormData.label.trim() !== '') {
        data.tags = searchFormData.label.split(',')
      }
    } else if (item === 'displayType') {
      if (!searchFormData.displayType) {
        data.displayTypeList = []
      }
      if (searchFormData.displayType && searchFormData.displayType.trim() !== '') {
        const temp = searchFormData.displayType.split(',')
        const _index = temp.indexOf('0')
        if (_index >= 0) {
          temp.splice(temp.indexOf('0'), 1)
        }
        data.displayTypeList = temp
      }
    } else if (item === 'settleRoleIds') {
      if (!data.roleIds) {
        data.roleIds = {}
      }
      if (searchFormData[item]) {
        data.roleIds[roleTemp[item]] = searchFormData[item]?.split(',')
      } else {
        data.roleIds[roleTemp[item]] = []
      }
    } else {
      if (searchFormData[item] !== '') {
        data[item] = searchFormData[item]
      }
    }
  })
  return data
}

export default defineComponent({
  name: 'AddMessage',
  components: { PlatAndRoles },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const { id, mode } = route.query
    const validateDisplayTime = (rule: any, value: any, callback: any) => {
      if (!value) {
        callback(new Error('请输入展示时间'))
      } else if (value && value.length === 2 && (!value[0] || !value[1])) {
        callback(new Error('请输入展示时间'))
      } else {
        const endTime = new Date(Date.parse(value[1]))
        const nowTime = new Date()
        if (nowTime > endTime) {
          callback(new Error('结束时间不能早于当前时间'))
        } else {
          callback()
        }
      }
    }
    const rules = reactive({
      displayTime: [{
        validator: validateDisplayTime, trigger: 'blur'
      }]
    })
    // 枚举状态数据，每次登陆或者刷新页面重新获取,存到store
    // ------------------------------------------------------------------------
    const msgStatusEnum = ref()
    const msgTypeEnum = ref()
    const msgDisplayEnum = ref()
    const platEnum = ref()
    const setEnumDataUnionOne = async() => {
      const res = await store.dispatch('enumData/setEnumDataUnionOne')
      return res
    }
    const formData = ref<AddMessageMSet>({
      // displayType: 0, // 显示形式 默认
      displayTypeList: [],
      tags: []
    })

    // 获取数据
    // ------------------------------------------------
    // 消息数据
    const loadMessageData = async (id) => {
      if (id) {
        const res = await getMessageDetail({ id })
        if (res.success && res.result) {
          formData.value = detailToFormData(res.result)
        }
      }
    }
    // 表单是否禁用
    const formDisabled = computed(() => !!route.query.mode && route.query.mode === 'view')
    // 表单提交
    // ------------------------------------------------
    const submitting = ref(false)
    const mainForm = ref()

    const handleSubmit = () => {
      mainForm.value.validate(valid => {
        valid && submit()
      })
    }
    const submit = async () => {
      loading.value = true
      const _data = toFormData(formData.value)
      const res = id ? await updateMessage(_data) : await addMessage(_data)
      loading.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'message_list' })
      }
    }
    // 标签相关
    // ---------------------------------------------
    const tagsInputVisible = ref(false)
    const newTagValue = ref('')
    // 新增标签输入框
    const showTagsInput = () => {
      tagsInputVisible.value = true
    }
    // 新增标签的事件
    const handleTagsInputConfirm = (event) => {
      if (newTagValue.value && formData.value.tags) {
        formData.value.tags.push(newTagValue.value)
      }
      tagsInputVisible.value = false
      newTagValue.value = ''
    }
    // 删除标签
    const handleTagClose = (tag) => {
      if (formData.value.tags) {
        formData.value.tags.splice(formData.value.tags.indexOf(tag), 1)
      }
    }
    const handleBack = () => {
      router.go(-1)
    }
    loadMessageData(id).then(() => {
      setEnumDataUnionOne().then(rs => {
        msgStatusEnum.value = store.state.enumData.enumInfo.msg_status
        msgTypeEnum.value = store.state.enumData.enumInfo.msg_type
        msgDisplayEnum.value = store.state.enumData.enumInfo.msg_dispaly
        platEnum.value = store.state.enumData.enumInfo.plat
      })
    })
    return {
      id,
      loading,
      platEnum,
      submitting,
      mainForm,
      formData,
      messageDisplayTypeEnum,
      tagsInputVisible,
      newTagValue,
      formDisabled,
      handleSubmit,
      showTagsInput,
      handleTagsInputConfirm,
      handleTagClose,
      rules,
      msgStatusEnum,
      msgTypeEnum,
      msgDisplayEnum,
      handleBack
    }
  }
})
</script>
<style lang="less" scoped>
.form-footer{
  text-align: right;
}
.form-message-container{
    width:600px;
}
.tag-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
.el-tag {
    margin-right: 10px;
    box-sizing: border-box;
    height: 32px;
    line-height: 32px;
    margin-bottom: 10px;
}
.button-new-tag {
    height: 32px;
    line-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
}
.input-new-tag {
    width: 90px;
    margin-right: 10px;
    vertical-align: bottom;
    margin-bottom: 10px;
}
.block-title_txt{
 text-align: right;
 padding-right:10px;
 box-sizing: border-box;
}
:deep(.el-date-editor .el-range-input){
  text-align: left;
}
</style>
