
import { defineComponent, reactive, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { addMessage, updateMessage, getMessageDetail } from '@/api/message'
import type { AddMessageMSet } from '@/api/model/messageModel'
import { messageDisplayTypeEnum } from '@/config/enums/message'
import PlatAndRoles from './components/PlatAndRoles.vue'

function toFormData(searchFormData: AddMessageMSet): AddMessageMSet {
  const roleTemp = {
    // admin: 'adminRoleIds',
    settle: 'settleRoleIds'
    // business: 'businessRoleIds'
  }
  const data: AddMessageMSet = {}
  delete searchFormData.label
  Object.keys(searchFormData).forEach(item => {
    if (item === 'displayTime' && searchFormData.displayTime && searchFormData.displayTime.length > 0) {
      data.displayStartTime = searchFormData.displayTime[0]
      data.displayEndTime = searchFormData.displayTime[1]
    } else if (item === 'tags') {
      if (searchFormData.tags && searchFormData.tags.length > 0) {
        data.label = searchFormData.tags.join(',')
      } else {
        data.label = ''
      }
    } else if (item === 'roleIds') {
      searchFormData.roleIds && Object.keys(searchFormData.roleIds).map(item => {
        if (searchFormData.roleIds) {
          data[roleTemp[item]] = searchFormData.roleIds[item].join(',')
        }
      })
    } else if (item === 'displayTypeList') {
      if (searchFormData.displayTypeList && searchFormData.displayTypeList.length > 0) {
        data.displayType = searchFormData.displayTypeList.join(',')
      } else {
        data.displayType = ''
      }
    } else if (item === 'displayType') {

    } else {
      if (searchFormData[item] !== '') {
        data[item] = searchFormData[item]
      }
    }
  })
  return data
}

function detailToFormData(searchFormData: AddMessageMSet): AddMessageMSet {
  const data: AddMessageMSet = {
    displayTime: [],
    forceTime: [],
    tags: []
  }
  const roleTemp = {
    adminRoleIds: 'admin',
    settleRoleIds: 'settle',
    businessRoleIds: 'business'
  }
  data.displayTime = ['', '']
  data.tags = []
  delete searchFormData.roleIds
  Object.keys(searchFormData).forEach(item => {
    if (item === 'displayStartTime') {
      if (data.displayTime === undefined) {
        data.displayTime = []
      }
      data.displayTime[0] = searchFormData.displayStartTime || ''
    } else if (item === 'displayEndTime') {
      if (data.displayTime === undefined) {
        data.displayTime = []
      }
      data.displayTime[1] = searchFormData.displayEndTime || ''
    } else if (item === 'label') {
      if (searchFormData.label === undefined) {
        searchFormData.label = ''
      }
      if (searchFormData.label.trim() !== '') {
        data.tags = searchFormData.label.split(',')
      }
    } else if (item === 'displayType') {
      if (!searchFormData.displayType) {
        data.displayTypeList = []
      }
      if (searchFormData.displayType && searchFormData.displayType.trim() !== '') {
        const temp = searchFormData.displayType.split(',')
        const _index = temp.indexOf('0')
        if (_index >= 0) {
          temp.splice(temp.indexOf('0'), 1)
        }
        data.displayTypeList = temp
      }
    } else if (item === 'settleRoleIds') {
      if (!data.roleIds) {
        data.roleIds = {}
      }
      if (searchFormData[item]) {
        data.roleIds[roleTemp[item]] = searchFormData[item]?.split(',')
      } else {
        data.roleIds[roleTemp[item]] = []
      }
    } else {
      if (searchFormData[item] !== '') {
        data[item] = searchFormData[item]
      }
    }
  })
  return data
}

export default defineComponent({
  name: 'AddMessage',
  components: { PlatAndRoles },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = ref(false)
    const { id, mode } = route.query
    const validateDisplayTime = (rule: any, value: any, callback: any) => {
      if (!value) {
        callback(new Error('请输入展示时间'))
      } else if (value && value.length === 2 && (!value[0] || !value[1])) {
        callback(new Error('请输入展示时间'))
      } else {
        const endTime = new Date(Date.parse(value[1]))
        const nowTime = new Date()
        if (nowTime > endTime) {
          callback(new Error('结束时间不能早于当前时间'))
        } else {
          callback()
        }
      }
    }
    const rules = reactive({
      displayTime: [{
        validator: validateDisplayTime, trigger: 'blur'
      }]
    })
    // 枚举状态数据，每次登陆或者刷新页面重新获取,存到store
    // ------------------------------------------------------------------------
    const msgStatusEnum = ref()
    const msgTypeEnum = ref()
    const msgDisplayEnum = ref()
    const platEnum = ref()
    const setEnumDataUnionOne = async() => {
      const res = await store.dispatch('enumData/setEnumDataUnionOne')
      return res
    }
    const formData = ref<AddMessageMSet>({
      // displayType: 0, // 显示形式 默认
      displayTypeList: [],
      tags: []
    })

    // 获取数据
    // ------------------------------------------------
    // 消息数据
    const loadMessageData = async (id) => {
      if (id) {
        const res = await getMessageDetail({ id })
        if (res.success && res.result) {
          formData.value = detailToFormData(res.result)
        }
      }
    }
    // 表单是否禁用
    const formDisabled = computed(() => !!route.query.mode && route.query.mode === 'view')
    // 表单提交
    // ------------------------------------------------
    const submitting = ref(false)
    const mainForm = ref()

    const handleSubmit = () => {
      mainForm.value.validate(valid => {
        valid && submit()
      })
    }
    const submit = async () => {
      loading.value = true
      const _data = toFormData(formData.value)
      const res = id ? await updateMessage(_data) : await addMessage(_data)
      loading.value = false
      if (res.success) {
        ElMessage.success('操作成功')
        router.push({ name: 'message_list' })
      }
    }
    // 标签相关
    // ---------------------------------------------
    const tagsInputVisible = ref(false)
    const newTagValue = ref('')
    // 新增标签输入框
    const showTagsInput = () => {
      tagsInputVisible.value = true
    }
    // 新增标签的事件
    const handleTagsInputConfirm = (event) => {
      if (newTagValue.value && formData.value.tags) {
        formData.value.tags.push(newTagValue.value)
      }
      tagsInputVisible.value = false
      newTagValue.value = ''
    }
    // 删除标签
    const handleTagClose = (tag) => {
      if (formData.value.tags) {
        formData.value.tags.splice(formData.value.tags.indexOf(tag), 1)
      }
    }
    const handleBack = () => {
      router.go(-1)
    }
    loadMessageData(id).then(() => {
      setEnumDataUnionOne().then(rs => {
        msgStatusEnum.value = store.state.enumData.enumInfo.msg_status
        msgTypeEnum.value = store.state.enumData.enumInfo.msg_type
        msgDisplayEnum.value = store.state.enumData.enumInfo.msg_dispaly
        platEnum.value = store.state.enumData.enumInfo.plat
      })
    })
    return {
      id,
      loading,
      platEnum,
      submitting,
      mainForm,
      formData,
      messageDisplayTypeEnum,
      tagsInputVisible,
      newTagValue,
      formDisabled,
      handleSubmit,
      showTagsInput,
      handleTagsInputConfirm,
      handleTagClose,
      rules,
      msgStatusEnum,
      msgTypeEnum,
      msgDisplayEnum,
      handleBack
    }
  }
})
