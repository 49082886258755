<template>
    <el-form inline :model="searchFormData" @submit.prevent="handleSubmit">
        <el-form-item prop="type" label="消息类型">
          <el-select v-model="searchFormData.msgType" placeholder="请选择" clearable style="width:120px">
            <el-option :key="item.dataKey" v-for="item in msgTypeEnum" :label="item.dataValue" :value="item.dataKey"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="status" label="消息状态">
          <el-select v-model="searchFormData.status" placeholder="请选择" clearable style="width:120px">
            <el-option label="全部" value="" />
            <el-option label="待生效" :value="0" />
            <el-option label="生效中" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" prop="created">
          <el-date-picker
            v-model="searchFormData.createDate"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            style="width: 220px"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item prop="keyWord" label="关键字">
          <el-input v-model="searchFormData.keyWord" placeholder="请输入" style="width:120px"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit">查询</el-button>
        </el-form-item>
    </el-form>
</template>
<script lang="ts">
import { defineComponent, PropType, toRef } from 'vue'
import { customerTypes, customerStates } from '@/config/enums/account'
import { MessageListParams } from '@/api/model/messageModel'
import { EnumSet } from '@/api/model/enumDataModel'

export default defineComponent({
  name: 'AccountSearchForm',
  emits: ['submit'],
  props: {
    modelValue: {
      type: Object as PropType<MessageListParams>,
      required: true
    },
    msgTypeEnum: {
      type: Object as PropType<EnumSet[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const searchFormData = toRef(props, 'modelValue')

    const handleSubmit = () => {
      emit('submit', searchFormData.value)
    }

    return {
      customerTypes,
      customerStates,
      searchFormData,
      handleSubmit
    }
  }
})
</script>
