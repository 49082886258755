
import { defineComponent, PropType, toRef } from 'vue'
import { customerTypes, customerStates } from '@/config/enums/account'
import { MessageListParams } from '@/api/model/messageModel'
import { EnumSet } from '@/api/model/enumDataModel'

export default defineComponent({
  name: 'AccountSearchForm',
  emits: ['submit'],
  props: {
    modelValue: {
      type: Object as PropType<MessageListParams>,
      required: true
    },
    msgTypeEnum: {
      type: Object as PropType<EnumSet[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const searchFormData = toRef(props, 'modelValue')

    const handleSubmit = () => {
      emit('submit', searchFormData.value)
    }

    return {
      customerTypes,
      customerStates,
      searchFormData,
      handleSubmit
    }
  }
})
