
import { defineComponent, ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { MessageListParams, ApiMessageListParams, MessageRowSet } from '@/api/model/messageModel'
import MessageListSearchForm from './components/MessageListSearchForm.vue'
import AppPagination from '@/components/AppPagination.vue'
import { getMessageList, deleteMessage, startMsg, changeMsgStatus } from '@/api/message'
import { useRouter } from 'vue-router'
import { dateFormat } from '@/utils/date'

function toFormData(searchFormData: MessageListParams): ApiMessageListParams {
  const data: MessageListParams = {}
  const { keyWord, msgType, status, createDate } = searchFormData
  if (keyWord !== '') {
    data.keyWord = keyWord
  }
  if (msgType !== '') {
    data.msgType = msgType
  }
  if (status !== '') {
    data.status = status
  }
  if (createDate && createDate.length > 0) {
    data.startDate = createDate[0] + ' 00:00:00'
    data.endDate = createDate[1] + ' 23:59:59'
  }
  return data
}

export default defineComponent({
  name: 'MessageList',
  components: { AppPagination, MessageListSearchForm },
  setup() {
    const router = useRouter()
    const store = useStore()
    const currentUserId = computed(() => store.state.user.userInfo?.id)
    // 枚举状态数据，每次登陆或者刷新页面重新获取,存到store
    // ------------------------------------------------------------------------
    const msgStatusEnum = ref()
    const msgTypeEnum = ref([])
    const msgDisplayEnum = ref()
    const setEnumDataUnionOne = async() => {
      const res = await store.dispatch('enumData/setEnumDataUnionOne')
      return res
    }
    setEnumDataUnionOne().then(rs => {
      msgStatusEnum.value = store.state.enumData.enumInfo.msg_status
      msgTypeEnum.value = store.state.enumData.enumInfo.msg_type
      msgDisplayEnum.value = store.state.enumData.enumInfo.msg_dispaly
      loadList()
    })

    // 列表加载
    // -------------------------------------------------
    // 搜索
    const searchFormData = reactive<MessageListParams>({})
    const loading = ref(false)
    const rows = ref<MessageRowSet[]>()
    const pager = reactive({
      page: 1,
      total: 0,
      pageSize: 10
    })
    const loadList = async (
      page: number = pager.page,
      pageSize: number = pager.pageSize
    ) => {
      loading.value = true
      const res = await getMessageList({
        ...toFormData(searchFormData),
        pageNo: page,
        pageSize: pageSize
      })
      loading.value = false
      if (res.success && res.result) {
        rows.value = res.result.data
        pager.total = res.result.total
        pager.page = page
        pager.pageSize = pageSize
      }
    }
    // 搜索
    const handleSearch = () => {
      loadList(1, pager.pageSize)
    }
    const handleSizeChange = (pageSize) => {
      loadList(1, pageSize)
    }
    const handleCurrentChange = (page: number) => {
      loadList(page)
    }

    // 增加/修改
    // -------------------------------------------------
    const submitting = ref(false)
    const dialogVisible = ref(false)
    const openDialog = () => {
      dialogVisible.value = true
    }
    const closeDiaolog = () => {
      dialogVisible.value = false
    }

    // 编辑
    // -------------------------------------------------
    const handleEdit = (id) => {
      router.push({
        name: 'message_list_add',
        query: {
          id
        }
      })
    }
    const handleView = (id) => {
      router.push({
        name: 'message_list_detail',
        query: {
          id
        }
      })
    }
    const handleDelete = async (id) => {
      const res = await deleteMessage({ id })
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      }
    }
    // 新增消息
    // -------------------------------------------------
    const handleAddMsg = (id) => {
      const _query = (id !== '') ? { id } : {}
      router.push({
        name: 'message_list_add',
        query: _query
      })
    }
    const formatCreateCol = (row, col) => {
      if (col.property === 'createdAt') {
        return dateFormat(row.createdAt)
      }
      return row[col.property] || '--'
    }
    const formatInCol = (row, col) => {
      if (col.property === 'displayStartTime') {
        return row.displayStartTime ? dateFormat(row.displayStartTime) : ''
      }
      return row[col.property] || '--'
    }
    // 获取状态值
    const getStatusTxt = (status, displayType) => {
      if (status === 0) {
        return '待生效'
      } else if (displayType) {
        const _list = displayType.split(',')
        let str = ''
        _list && _list.map((item, index) => {
          if (item.toString() !== '0') {
            str = str + msgDisplayEnum.value[item].dataValue + (index === (_list.length - 1) ? '' : '/')
          } else {
            str = str + '默认' + (index === (_list.length - 1) ? '' : '/')
          }
        })
        return str
      } else {
        return '默认'
      }
    }
    // 启用
    const handleStartMessage = async (id) => {
      const res = await startMsg({ id })
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      }
    }
    // 改变状态
    const handleChangeStatus = async (id, msgStatus) => {
      const res = await changeMsgStatus({ id, msgStatus })
      if (res.success) {
        ElMessage.success('操作成功')
        loadList()
      }
    }
    // 更多操作
    // --------------------------------------------------------
    const handleMore = (command) => {
      // 1 预览
      // 2 启用
      // 3 置顶
      // 4 滚动
      // 5 全屏
      // 6 取消置顶
      // 7 取消滚动
      // 8 取消全屏
      if (command.index === 1) {
        handleView(command.row.id)
      } else if (command.index === 2) {
        handleStartMessage(command.row.id)
      } else if (command.index === 3) {
        handleChangeStatus(command.row.id, 1)
      } else if (command.index === 4) {
        handleChangeStatus(command.row.id, 2)
      } else if (command.index === 5) {
        handleChangeStatus(command.row.id, 3)
      } else if (command.index === 6) {
        handleChangeStatus(command.row.id, 1)
      } else if (command.index === 7) {
        handleChangeStatus(command.row.id, 2)
      } else if (command.index === 8) {
        handleChangeStatus(command.row.id, 3)
      }
    }
    const composeMoreValue = (index, row) => {
      return {
        index,
        row
      }
    }
    return {
      currentUserId,
      searchFormData,
      loadList,
      loading,
      rows,
      pager,
      submitting,
      dialogVisible,
      router,
      msgStatusEnum,
      msgTypeEnum,
      openDialog,
      closeDiaolog,
      handleSizeChange,
      handleCurrentChange,
      handleEdit,
      handleView,
      handleDelete,
      handleSearch,
      handleAddMsg,
      formatCreateCol,
      formatInCol,
      getStatusTxt,
      handleStartMessage,
      handleChangeStatus,
      handleMore,
      composeMoreValue
    }
  }
})
