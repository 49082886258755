import { get, post, request } from '@/utils/http/request'
import type { RequestOptions } from '@/utils/http/types'
import { MessageConfigReult, MessageParams, MessageListResult, AddMessageMSet } from './model/messageModel'

// 消息配置列表查询
export function getMessageSetting() {
  return get<MessageConfigReult>('sys/enum/msg/setting')
}

// 消息配置添加
export function addEnum(
  data: {
    dataType: string,
    dataGroup: string,
    dataKey: string,
    dataValue: string
},
  options?: RequestOptions
) {
  return post('sys/enum', data, options)
}

// 消息配置删除
export function removeEnum(
  params: {
    id: string
  },
  options?: RequestOptions
) {
  return request({
    url: 'sys/enum',
    method: 'delete',
    params
  }, options)
}

// 消息列表
export function getMessageList(params: MessageParams, options?: RequestOptions) {
  return get<MessageListResult>('sys/msg/page', params, options)
}

// 新增消息
export function addMessage(
  data: AddMessageMSet,
  options?: RequestOptions
) {
  return post('sys/msg', data, options)
}

// 更新消息
export function updateMessage(
  data: AddMessageMSet,
  options: RequestOptions = {}
) {
  options.useQueryString = false
  return request({
    url: 'sys/msg',
    method: 'put',
    data
  }, options)
}

// 消息详情
export function getMessageDetail(params: { id: string }, options?: RequestOptions) {
  return get<AddMessageMSet>('sys/msg/detail', params, options)
}

// 根据系统查询默认角色
export function getSystemRoles(params: { plat: string }, options?: RequestOptions) {
  return get('sys/msg/role', params, options)
}

// 删除消息
export function deleteMessage(params: { id: number }, options?: RequestOptions) {
  return request({
    url: 'sys/msg',
    method: 'delete',
    params
  }, options)
}

// 启用消息
export function startMsg(params: { id: string }, options?: RequestOptions) {
  return get('sys/msg/status', params, options)
}

// 改变状态
export function changeMsgStatus(params: { id: string, msgStatus: string }, options?: RequestOptions) {
  return get('sys/msg/msgStatus', params, options)
}
