
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getMessageDetail } from '@/api/message'

export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const handleBack = () => {
      router.go(-1)
    }
    const detalData = ref()
    const getDetailData = async (id) => {
      const params = {
        id
      }
      const rs = await getMessageDetail(params, { loading: true })
      if (rs && rs.success && rs.result) {
        detalData.value = rs.result
      }
    }
    const { id } = route.query
    getDetailData(id)
    return {
      detalData,
      handleBack
    }
  }
})
