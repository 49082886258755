<template>
<app-page>
  <div class="detail" v-if="detalData">
    <div class="detail_title">{{detalData.title}}</div>
    <div class="detail_time">{{detalData.forceStartTime}} {{detalData.msgType}}</div>
    <div class="detail-body">
      <div class="detail-body_txt">
        {{detalData.content}}
        <a v-if="detalData.relatedUrl" target="_blank" :href="detalData.relatedUrl">点击前往&gt;&gt;</a>
      </div>
      <div class="detail-body_tags" v-if="detalData.label">
        <span>关键字：</span>
        <span class="-tag" :key="index" v-for="(item, index) in detalData.label.split(',')">{{item}}</span>
      </div>
      <div class="btn-box">
        <el-button type="" @click="handleBack">返回</el-button>
      </div>
    </div>
  </div>
</app-page>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getMessageDetail } from '@/api/message'

export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const handleBack = () => {
      router.go(-1)
    }
    const detalData = ref()
    const getDetailData = async (id) => {
      const params = {
        id
      }
      const rs = await getMessageDetail(params, { loading: true })
      if (rs && rs.success && rs.result) {
        detalData.value = rs.result
      }
    }
    const { id } = route.query
    getDetailData(id)
    return {
      detalData,
      handleBack
    }
  }
})
</script>
<style lang="less" scoped>
.detail{
  text-align: center;
  &_title{
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-top: 135px;
  }
  &_time{
    margin-top: 24px;
    font-size: 12px;
    color: #333;
  }
  &-body{
    width: 60%;
    margin: 30px auto 0 auto;
    text-align: left;
    font-size: 12px;
    color: #333;
    font-weight: bold;
    line-height: 24px;
    &_tags{
      font-size: 14px;
      margin-top: 40px;
      .-tag{
        margin-right: 10px;
      }
    }
    .btn-box{
      margin-top: 50px;
      text-align: center;
    }
  }
}
</style>
