<template>
  <div class="plat-box"
    :key="item.id"
    v-for="item in platEnum">
    <div>
      <div class="plat-box_header">
        <el-checkbox
          size="small"
          v-model="checkObjAll[item.dataKey]"
          :indeterminate="objIsIndeterminate[item.dataKey]"
          @change="handleCheckAllChange($event, item.dataKey)">
            {{item.dataValue}}
        </el-checkbox>
      </div>
      <div>
        <el-checkbox-group
          class="plat-box_list"
          size="small"
          v-model="checkedObj[item.dataKey]"
          @change="handleCheckedCitiesChange($event, item.dataKey)">
          <el-checkbox v-for="it in roleObj[item.dataKey]" :key="it.id" :label="it.id.toString()">
            {{it.roleName}}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, toRef, ref, watch } from 'vue'
import { RoleIdsSet } from '@/api/model/messageModel'
import { EnumSet } from '@/api/model/enumDataModel'
import { getSystemRoles } from '@/api/message'

export default defineComponent({
  name: 'PlatAndRoles',
  props: {
    modelValue: {
      type: Object as PropType<RoleIdsSet>,
      required: true
    },
    platEnum: {
      type: Object as PropType<EnumSet[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const searchFormData = toRef(props, 'modelValue')
    const handleCheckAllChange = (val: boolean, type) => {
      const allIds = roleObj.value[type].map(item => {
        return item.id.toString()
      })
      checkedObj.value[type] = val ? allIds : []
      objIsIndeterminate.value[type] = false
    }
    const handleCheckedCitiesChange = (value: string[], type) => {
      const checkedCount = value.length
      const allIds = roleObj.value[type].map(item => {
        return item.id.toString()
      })
      checkObjAll.value[type] = checkedCount === allIds.length
      objIsIndeterminate.value[type] = checkedCount > 0 && checkedCount < allIds.length
    }
    const loadData = () => {
      checkedObj.value = props.modelValue || {
        // admin: [],
        // business: [],
        settle: []
      }
      checkedObj.value && Object.keys(checkedObj.value).map(item => {
        const checkedCount = checkedObj.value[item].length
        const allIds = roleObj.value[item].map(item => {
          return item.id.toString()
        })
        checkObjAll.value[item] = checkedCount === allIds.length
        objIsIndeterminate.value[item] = checkedCount > 0 && checkedCount < allIds.length
      })
    }
    // 获取角色次数
    const roleLoadCount = ref(0)
    // 获取角色
    const roleObj = ref({})
    // 勾选的数据
    const checkedObj = ref({})
    // 系统是否全部勾选
    const checkObjAll = ref({})
    // 是否半选
    const objIsIndeterminate = ref({})
    // 获取平台角色勾选数据
    const getSysRoles = async (plat) => {
      const res = await getSystemRoles({ plat })
      if (res.success && res.result) {
        roleLoadCount.value++
        roleObj.value[plat] = res.result
      }
    }
    props.platEnum && Object.keys(props.platEnum).map((item) => {
      checkedObj.value[item] = []
      checkObjAll.value[item] = false
      objIsIndeterminate.value[item] = false
      getSysRoles(item).then(rs => {
        if (roleLoadCount.value === Object.keys(props.platEnum).length) {
          loadData()
        }
      })
    })
    const sh = ref(false)
    watch(() => [checkedObj], () => {
      emit('update:modelValue', checkedObj)
    },
    { deep: true } // 深度监听的参数
    )
    return {
      searchFormData,
      roleObj,
      checkedObj,
      checkObjAll,
      objIsIndeterminate,
      sh,
      handleCheckAllChange,
      handleCheckedCitiesChange
    }
  }
})
</script>
<style lang="less" scoped>
.plat-box{
  // padding: 0 10px;
  // box-sizing: border-box;
  &:nth-of-type(1){
    border-bottom: none;
  }
  &:nth-last-of-type(1){
    border-bottom: 1px solid #d9d9d9;
  }
  :hover{
    background: #f4faff;
  }
  border: 1px solid #d9d9d9;
  .el-checkbox-group{
    border-top: 1px solid #d9d9d9;
  }
  &_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 15px;
    .el-icon-button{
      font-size: 16px;
    }
  }
  &_list{
    padding: 0 15px 0 15px;
  }
}
:deep(el-radio__label) {
  font-size: 14px;
}
.plat-box :deep(.el-checkbox__label){
  font-size: 14px;
}
</style>
