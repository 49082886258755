<template>
  <app-page>
    <div class="form-container form-message-container">
      <block-title>消息配置</block-title>
      <el-form
        ref="mainForm"
        v-loading="loading"
        :model="formData"
        @submit.prevent="handleSubmit"
      >
        <el-form-item
          prop="teamName"
          label="消息类型"
        >
          <tags-control
            v-if="msgConfigData && msgConfigData.msg_type"
            v-model="msgConfigData.msg_type"
            :tagType="'msg_type'"
            @add="addMessageTags"
            @remove="removeMessageTags">
          </tags-control>
        </el-form-item>
        <el-form-item
          prop="teamName"
          label="消息状态"
        >
          <tags-control
            v-if="msgConfigData && msgConfigData.msg_status"
            v-model="msgConfigData.msg_status"
            @add="addMessageTags"
            :tagType="'msg_status'"
            @remove="removeMessageTags">
          </tags-control>
        </el-form-item>
      </el-form>
    </div>
  </app-page>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { getMessageSetting, addEnum, removeEnum } from '@/api/message'
import { MessageConfigReult } from '@/api/model/messageModel'
import { messageDisplayTypeEnum } from '@/config/enums/message'
import TagsControl from '@/components/TagsControl.vue'

export default defineComponent({
  name: 'MessageConfing',
  components: { TagsControl },
  setup() {
    // 获取数据
    // ------------------------------------------------
    // 消息状态和消息类型
    const msgConfigData = ref<MessageConfigReult>()
    const loadPageData = async () => {
      const res = await getMessageSetting()
      if (res.success && res.result) {
        msgConfigData.value = res.result
      }
    }
    loadPageData()
    const addMessageTagsServe = async (data) => {
      const res = await addEnum(data, { loading: true })
      if (res.success) {
        loadPageData()
        return true
      }
    }
    const removeMessageTagsServe = async (id) => {
      const res = await removeEnum({ id }, { loading: true })
      if (res.success) {
        loadPageData()
        return true
      }
    }
    const addMessageTags = (data, cb) => {
      addMessageTagsServe(data).then(rs => {
        cb(rs)
      })
    }
    const removeMessageTags = (id, cb) => {
      removeMessageTagsServe(id).then(rs => {
        cb(rs)
      })
    }
    return {
      msgConfigData,
      messageDisplayTypeEnum,
      addMessageTags,
      removeMessageTags
    }
  }
})
</script>
<style lang="less" scoped>
</style>
