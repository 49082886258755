// 消息状态
export const messageStatusEnum = {
  1: '待生效',
  2: '待生效',
  3: '默认',
  4: '置顶',
  5: '滚动',
  6: '全屏'
}

// 显示形式
export const messageDisplayTypeEnum = {
  1: '默认',
  2: '置顶',
  3: '滚动',
  4: '全屏'
}
