
import { defineComponent, reactive, ref } from 'vue'
import { getMessageSetting, addEnum, removeEnum } from '@/api/message'
import { MessageConfigReult } from '@/api/model/messageModel'
import { messageDisplayTypeEnum } from '@/config/enums/message'
import TagsControl from '@/components/TagsControl.vue'

export default defineComponent({
  name: 'MessageConfing',
  components: { TagsControl },
  setup() {
    // 获取数据
    // ------------------------------------------------
    // 消息状态和消息类型
    const msgConfigData = ref<MessageConfigReult>()
    const loadPageData = async () => {
      const res = await getMessageSetting()
      if (res.success && res.result) {
        msgConfigData.value = res.result
      }
    }
    loadPageData()
    const addMessageTagsServe = async (data) => {
      const res = await addEnum(data, { loading: true })
      if (res.success) {
        loadPageData()
        return true
      }
    }
    const removeMessageTagsServe = async (id) => {
      const res = await removeEnum({ id }, { loading: true })
      if (res.success) {
        loadPageData()
        return true
      }
    }
    const addMessageTags = (data, cb) => {
      addMessageTagsServe(data).then(rs => {
        cb(rs)
      })
    }
    const removeMessageTags = (id, cb) => {
      removeMessageTagsServe(id).then(rs => {
        cb(rs)
      })
    }
    return {
      msgConfigData,
      messageDisplayTypeEnum,
      addMessageTags,
      removeMessageTags
    }
  }
})
