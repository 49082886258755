<template>
  <div class="tag-container">
    <el-tag
        v-for="(tag, index) in formData"
        :key="index"
        closable
        :disable-transitions="false"
        @close="handleTagClose(index, tag.id)">
        {{ tag.dataValue }}
    </el-tag>
    <el-input
        v-if="tagsInputVisible"
        v-model="newTagValue"
        class="input-new-tag"
        size="small"
        @keyup.enter="handleTagsInputConfirm"
        @blur="handleTagsInputConfirm">
    </el-input>
    <el-button class="button-new-tag" size="small" @click="showTagsInput">+ 新增</el-button>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  watch,
  ref,
  reactive,
  PropType,
  onMounted
} from 'vue'
import type { MessageTagsSet } from '@/api/model/messageModel'
// import { findByTeam, findByTeamAndOrg } from '@/api/customer'

export default defineComponent({
  name: 'TagsControl',
  components: { },
  emits: ['add', 'remove'],
  props: [
    'modelValue',
    'tagType',
    'limit'
  ],
  setup(props, { emit }) {
    // 标签相关
    // ---------------------------------------------
    // const tags = props.modelValue
    const formData = ref<MessageTagsSet[]>(
      props.modelValue
    )
    const tagsInputVisible = ref(false)
    const newTagValue = ref<string>()
    // 新增标签输入框
    const showTagsInput = () => {
      tagsInputVisible.value = true
    }
    // 新增标签的事件
    const handleTagsInputConfirm = () => {
      if (newTagValue.value && newTagValue.value.toString().trim() !== '') {
        const _data = {
          dataType: props.tagType,
          dataGroup: props.tagType,
          dataKey: newTagValue.value,
          dataValue: newTagValue.value
        }
        // 异步提交新增标签
        emit('add', _data, (res) => {
          if (res) {
            if (newTagValue.value) {
              formData.value.push(_data)
            }
            tagsInputVisible.value = false
            newTagValue.value = ''
          }
        })
      } else {
        tagsInputVisible.value = false
        newTagValue.value = ''
      }
    }
    // 删除标签
    const handleTagClose = (index, id) => {
      emit('remove', id, (res) => {
        if (res) {
          // 异步删除标签
          if (formData.value) {
            formData.value.splice(index, 1)
          }
        }
      })
    }
    watch(() => [props.modelValue], () => {
      formData.value = props.modelValue
    },
    { deep: true, immediate: true } // 深度监听的参数
    )
    return {
      formData,
      tagsInputVisible,
      newTagValue,
      handleTagClose,
      handleTagsInputConfirm,
      showTagsInput
    }
  }
})
</script>
<style scoped lang="less">
  .tag-container{
    display: flex;
    flex-wrap: wrap;
    vertical-align: bottom;
    align-items: center;
  }
.el-tag {
    margin-right: 10px;
    box-sizing: border-box;
    height: 32px;
    line-height: 32px;
    margin-bottom: 10px;
}
.button-new-tag {
    height: 32px;
    line-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
}
.input-new-tag {
    width: 90px;
    margin-right: 10px;
    vertical-align: bottom;
    margin-bottom: 10px;
}
</style>
